import React,{ Component } from "react";

import KaspTokens from "../contracts/KaspTokens.json";
import KaspTokenSale from "../contracts/KaspTokenSale.json";
import getWeb3 from "../getWeb3";


class Dashboard extends Component{
    constructor(props){
        super(props);
        this.state = {
            address:'',
            userId:'',
            KaspTokenSaleInstance:'',
            balance:'',
            symbol:'',
            tokenSold:'',
            available:'',
            tokenprice:'',
            user:''
        };
        this.logOut = this.logOut.bind(this);
    }

    logOut(){
        console.log('logout')
        localStorage.removeItem("user");
        this.setState({userId: -1} );
        window.location.href='/login'
    }

    async loadBlockChain() {
        const web3 = await getWeb3();
        //const accounts = web3.eth.getAccounts().then();
        const accounts = await web3.eth.getAccounts();
        this.setState({address: accounts});
        const networkId = await web3.eth.net.getId();
        const KaspTokensInstance = new web3.eth.Contract(
          KaspTokens.abi,
          KaspTokens.networks[networkId] &&
            KaspTokens.networks[networkId].address
        );
        console.log(KaspTokensInstance);
    
        const KaspTokenSaleInstance = new web3.eth.Contract(
          KaspTokenSale.abi,
          KaspTokenSale.networks[networkId] &&
            KaspTokenSale.networks[networkId].address
        );
        this.setState({KaspTokenSaleInstance: KaspTokenSaleInstance});
    
        const balance = await KaspTokensInstance.methods
              .balanceOf(accounts[0])
              .call();
              console.log(balance);
              this.setState({balance: balance});
    
            const symbol = await KaspTokensInstance.methods.symbol().call(); // symbol
            this.setState({symbol: symbol});
    
            const to_name = await KaspTokensInstance.methods.name().call();
    
            const tokenSold = await KaspTokenSaleInstance.methods
              .tokenSold()
              .call();
              this.setState({tokenSold: tokenSold});
            const available = await KaspTokenSaleInstance.methods
              .tokensLeft()
              .call();
              
            const sum = ~~available + ~~tokenSold;
            this.setState({available: sum});
            const tokenprice = await KaspTokenSaleInstance.methods
              .tokenprice()
              .call();
              console.log(tokenSold);
              this.setState({tokenprice: tokenprice});
    
    
      }
    componentDidMount(){
        //this.loadBlockChain()
        console.log('hit ')
        setTimeout(() => {
            console.log(this.state)
            var user = localStorage.getItem('user');
            if(user){
                user = JSON.parse(user);
                user.address = this.state.address[0];
                user.balance = this.state.balance;
                user.symbol  = this.state.symbol;
                localStorage.setItem('user', JSON.stringify(user));
                this.setState({user: user });
            }else{
                window.location.href='/login';
            }
        }, 1500);
        
    }

    render(){
        return(
            <>
            <header class="menu-sticky">
                <div class="topnav" id="myTopnav">
                
                <a href="./">VELOCITY MONEY</a>
                
                { this.state.userId != -1 && 
                <a class="log" onClick={this.logOut}>
                        Logout
                </a> }
                { this.state.userId != -1 && 
                <a class="log" href="./dashboard">
                        Dashboard
                </a> }

                { this.state.userId == -1 && 
                <a class="log" href="./login">
                        LOGIN
                </a> }
                <a href="javascript:void(0);" class="icon" onclick="myFunction()">
                    <i class="fa fa-bars"></i>
                </a>
                </div>
            </header>
            <div class="main-contain">
                <section class="iq-tranding-Platform trending-2">
                    <div class="container-floud">
                       
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="heading-title">
                                    
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="heading-title header-test">
                                    <p>{this.state.user.address } &nbsp;&nbsp;
                                    || &nbsp;&nbsp;
                                    {this.state.user.balance } {this.state.user.symbol }
                                    </p>
                                </div>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div className="col-sm-2 overview-block-pb">
                                <ul className="menu">
                                    <li><a href="/dashboard">DASHBOARD</a> </li>
                                    <li><a href="/rollmaster">User role master</a> </li>
                                    <li><a href="/adduser">Subscription Master</a> </li>
                                    <li><a href="/invite">Invite</a> </li>
                                    
                                    {this.state.userType !=1  &&
                                    <>
                                    <li><a href="https://shekel.world/coin">Coin</a> </li>
                                    <li><a href="https://shekel.world/marketplace">Marketplace</a> </li>
                                    <li><a href="https://shekel.world/enjoy">Enjoy</a> </li>
                                    
                                    </>}
                                    <li><a href="/report">Reports & Graphs</a> </li>
                                </ul>
                            </div>
                            <div className="col-sm-10 white-background">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Percentage</th>
                                            <th>Coin</th>
                                            <th>Holding period(days)</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>8.33</td>
                                            <td>799.8</td>
                                            <td>2023-01-01</td>
                                            <td>Completed</td>
                                        </tr>
                                        <tr>
                                            <td>8.33</td>
                                            <td>799.8</td>
                                            <td>2023-01-01</td>
                                            <td>Completed</td>
                                        </tr>
                                        <tr>
                                            <td>8.33</td>
                                            <td>799.8</td>
                                            <td>2023-01-01</td>
                                            <td>Completed</td>
                                        </tr>
                                        <tr>
                                            <td>8.33</td>
                                            <td>799.8</td>
                                            <td>2023-01-01</td>
                                            <td>Completed</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            </>
        )
    }
}
export default Dashboard;