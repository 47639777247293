import React, { Component } from 'react';
import Button from "react-bootstrap/Button";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import './Login.css';
import ReCAPTCHA from "react-google-recaptcha";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            login: true,
            name: '',
            com_name: '',
            con_per: '',
            age: '',
            pnone: '',
            gender: '',
            coinName: '',
            symbol: 'SHEKEL',
            coin_des: '',
            treas: '',
            roles: [],
            role: '',
            countrys: [],
            loginEmailError: '',
            loginPasswordError: '',
            captchaVal: '',
            otp: '',
            enterOtrp: '',
            userData: ''
        }
        this.userLogin = this.userLogin.bind(this);
        this.userSignup = this.userSignup.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handelChanges = this.handelChanges.bind(this);

        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleContactPerChange = this.handleContactPerChange.bind(this);
        this.handleAgeChange = this.handleAgeChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleGenderChange = this.handleGenderChange.bind(this);
        this.handleCoinNameChange = this.handleCoinNameChange.bind(this);
        this.handleSymbolChange = this.handleSymbolChange.bind(this);
        this.handleCoinDesChange = this.handleCoinDesChange.bind(this);
        this.handleTreasChange = this.handleTreasChange.bind(this);
        this.getRole = this.getRole.bind(this);
        this.checkOtp = this.checkOtp.bind(this);
    }
    getRole() {
        fetch(`
    https://crearecoin.com/api/coin/get_role.php`)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({ roles: data.result });
            });
    }
    getCountrys() {
        fetch(`
    https://crearecoin.com/api/coin/get_all_countrys.php`)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({ countrys: data.result });
            });
    }

    handleCompanyChange(e) {
        this.setState({ com_name: e.target.value });
    }
    handleContactPerChange(e) {
        this.setState({ con_per: e.target.value });
    }
    handleAgeChange(e) {
        this.setState({ age: e.target.value });
    }
    handlePhoneChange(e) {
        this.setState({ pnone: e.target.value });
    }
    handleGenderChange(e) {
        this.setState({ gender: e.target.value });
    }
    handleCoinNameChange(e) {
        this.setState({ coinName: e.target.value });
    }
    handleSymbolChange(e) {
        this.setState({ symbol: e.target.value });
    }
    handleCoinDesChange(e) {
        this.setState({ coin_des: e.target.value });
    }
    handleTreasChange(e) {
        this.setState({ treas: e.target.value });
    }


    handleEmailChange(e) {
        this.setState({ email: e.target.value });
    }
    handleNameChange(e) {
        this.setState({ name: e.target.value });
    }
    handlePasswordChange(e) {
        this.setState({ password: e.target.value });
    }
    handelChanges() {
        this.setState({ login: !this.state.login });
        console.log(this.state.login)
    }

    userLogin() {
        console.log(this.state)
        if (this.state.email == '') return this.setState({ loginEmailError: 'Please enter Email address' });

        if (this.state.password == '') return this.setState({ loginPasswordError: 'Please enter Password' });
        /* if(this.state.captchaVal ==''){
          return;
        } */
        fetch(`
    https://crearecoin.com/api/coin/login.php?
    email=${this.state.email}&password=${this.state.password}`)
            .then(response => response.json())
            .then(data => {
                if (!data.error) {
                    this.setState({ userData: data.result })
                    localStorage.setItem('user', JSON.stringify(data.result));
                    window.location.href = "/dashboard";
                    //localStorage.setItem('user', JSON.stringify(data.result));
                    //window.lo("/");
                    // let otp = data.otp;
                    // this.setState({otp: otp});

                } else {
                    this.setState({ loginEmailError: data.result });
                }
                console.log(data)
            });

    }
    userSignup() {

        fetch(`
  https://crearecoin.com/api/coin/sign-up.php?
  email=${this.state.email}&password=${this.state.password}&name=${this.state.name}
  &com_name=${this.state.com_name}&con_per=${this.state.con_per}&age=${this.state.age}
  &pnone=${this.state.pnone}&gender=${this.state.gender}&coinName=${this.state.coinName}
  &symbol=${this.state.symbol}&coin_des=${this.state.coin_des}&treas=${this.state.treas}
  `)
            .then(response => response.json())
            .then(data => {
                this.setState({ login: !this.state.login });
            });

    }
    checkOtp() {
        console.log(this.state)
        if (this.state.otp == this.state.enterOtrp) {
            localStorage.setItem('user', JSON.stringify(this.state.userData));
            window.location.href = "/dashboard";
        } else {
            this.setState({ loginEmailError: 'Please enter currect OTP' });
            this.setState({ otp: '' });
        }

    }
    onChange(value) {
        console.log("Captcha value:", value);
      }

    componentDidMount() {
        console.log('hit ')
        this.getRole();
        this.getCountrys();
    }
    captchaChange(value) {


        //this.setState({captchaVal :  '1'});
        console.log(value)

    }

    render() {

        return (
            <div>
                {this.state.login &&
                    <div id="login" >
                        <div class="container_">
                            <div id="login-row" class="row justify-content-center align-items-center">
                                <div id="login-column" class="col-md-6">
                                    <div id="login-box" class="col-md-12">
                                        <div style={{textAlign:'center',paddingTop:'8px'}}>
                                        <a href="./">
                                            {/* <img class="imggolgo" src="./shkl.jpeg" /> */}
                                            <img class="img-logo" src="./velocity.png"
                                                style={{ height: '100px' }}
                                                alt="Velocity" />
                                        </a>
                                        </div>

                                        <form id="login-form" class="form" action="" method="post">
                                            <h3 class="text-center text-info">Sign in</h3>
                                            <div class="form-group">
                                                <label for="username" class="text-info">Username:</label><br />
                                                <input type="text" name="username" id="username" class="form-control"
                                                    value={this.state.email}
                                                    onChange={this.handleEmailChange}
                                                />
                                                {this.state.loginEmailError != '' &&
                                                    <label for="username" class="text-danger">{this.state.loginEmailError}</label>}
                                            </div>
                                            <div class="form-group">
                                                <label for="password" class="text-info">Password:</label><br />
                                                <input type="password" name="password" id="password" class="form-control"
                                                    value={this.state.password}
                                                    onChange={this.handlePasswordChange}
                                                />
                                                {this.state.loginPasswordError != '' &&
                                                    <label for="username" class="text-danger">{this.state.loginPasswordError}</label>}
                                            </div>
                                            <div class="form-group">
                                                <ReCAPTCHA
                                                sitekey="6LdoJg0pAAAAAEd_DZ1N0BQdf05mstzyZyBejv6h"
                                                onChange={this.onChange}
                                            />
                                        </div>
                                            {this.state.otp == '' &&
                                                <>
                                                    <div class="form-group" >

                                                    </div>
                                                    {<div class="form-group">
                                                        <Button onClick={this.userLogin}>Login</Button>

                                                    </div>}
                                                </>
                                            }
                                            {this.state.otp != '' &&
                                                <div>
                                                    <div class='form-group'>
                                                        <input type='text' class='form-control'
                                                            placeholder='Enter OTP'
                                                            value={this.state.enterOtrp}
                                                            onChange={(e) => this.setState({ enterOtrp: e.target.value })}
                                                        />
                                                        <button type='button' class='btn btn-success'
                                                            onClick={this.checkOtp}
                                                        >Enter</button>
                                                    </div>
                                                </div>
                                            }



                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.login == 'ddd' &&
                    <div id="login" >
                        <div class="container_">
                            <div id="login-row" class="row justify-content-center align-items-center">
                                <div id="login-column" class="col-md-6">
                                    <div id="login-box" class="col-md-12">
                                        <a href="./">
                                            <img class="imggolgo" src="./shkl.jpeg" /></a>
                                        <form id="login-form" class="form sign-up" action="" method="post">
                                            <h3 class="text-center text-info">Sign up</h3>
                                            <div class="row">
                                                <div class="form-group col-sm-6">
                                                    <label for="username" class="text-info">Name:</label><br />
                                                    <input type="text" name="name" class="form-control"
                                                        value={this.state.name}
                                                        onChange={this.handleNameChange}
                                                    />
                                                </div>
                                                <div class="form-group col-sm-6">
                                                    <label for="username" class="text-info">Company name:</label><br />
                                                    <input type="text" name="company_name" class="form-control"
                                                        value={this.state.com_name}
                                                        onChange={this.handleCompanyChange}
                                                    />
                                                </div>
                                                <div class="form-group col-sm-6">
                                                    <label for="username" class="text-info">Country: </label><br />
                                                    <select class="form-control" >
                                                        <option value="">Select</option>
                                                        {this.state.countrys &&
                                                            this.state.countrys.map((coin, index) => (
                                                                <option key={index}
                                                                    value={coin.name}
                                                                >
                                                                    {coin.name}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div class="form-group col-sm-6">
                                                    <label for="username" class="text-info">Symbol :</label><br />
                                                    <input type="text" name="symbol" class="form-control"
                                                        value={this.state.symbol}
                                                        onChange={this.handleSymbolChange}
                                                    />
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="form-group col-sm-6">
                                                    <label for="username" class="text-info">Contact person:</label><br />
                                                    <input type="text" name="contect_per" class="form-control"
                                                        value={this.state.con_per}
                                                        onChange={this.handleContactPerChange}
                                                    />
                                                </div>
                                                <div class="form-group col-sm-6">
                                                    <label for="username" class="text-info">Age:</label><br />
                                                    <input type="text" name="age" class="form-control"
                                                        value={this.state.age}
                                                        onChange={this.handleAgeChange}
                                                    />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-sm-6">
                                                    <label for="username" class="text-info">Phone:</label><br />
                                                    <input type="text" name="phone" class="form-control"
                                                        value={this.state.pnone}
                                                        onChange={this.handlePhoneChange}
                                                    />
                                                </div>
                                                <div class="form-group col-sm-6">
                                                    <label for="username" class="text-info">Gender:</label><br />
                                                    <select class="form-control"
                                                        value={this.state.gender}
                                                        onChange={this.handleGenderChange}>
                                                        <option value="">Select</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                        <option value="other">Other</option>

                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-sm-6">
                                                    <label for="username" class="text-info">Coin name:</label><br />
                                                    <input type="text" name="coinName" class="form-control"
                                                        value={this.state.coinName}
                                                        onChange={this.handleCoinNameChange}
                                                    />
                                                </div>

                                                <div class="form-group col-sm-6">
                                                    <label for="username" class="text-info">Role :</label><br />
                                                    <select value="Radish" class="form-control">
                                                        <option value="">Select</option>
                                                        {this.state.roles &&
                                                            this.state.roles.map((coin, index) => (
                                                                <option key={index}
                                                                    value={coin.name}
                                                                >
                                                                    {coin.name}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label for="username" class="text-info">Coin description:</label><br />
                                                <input type="text" name="coinDes" class="form-control"
                                                    value={this.state.coin_des}
                                                    onChange={this.handleCoinDesChange}
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label for="username" class="text-info">Treasury description :</label><br />
                                                <input type="text" name="treas" class="form-control"
                                                    value={this.state.treas}
                                                    onChange={this.handleTreasChange}
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label for="username" class="text-info">Email:</label><br />
                                                <input type="text" name="username" id="username" class="form-control"
                                                    value={this.state.email}
                                                    onChange={this.handleEmailChange}
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label for="password" class="text-info">Password:</label><br />
                                                <input type="password" name="password" id="password" class="form-control"
                                                    value={this.state.password}
                                                    onChange={this.handlePasswordChange}
                                                />
                                            </div>
                                            <div class="form-group">
                                                <Button onClick={this.userSignup}>Sign up</Button>
                                                <p onClick={this.handelChanges} style={{ textAlign: "right" }}>Sign-In </p>
                                            </div>


                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Login;