import React, { Component } from 'react';
import Button from "react-bootstrap/Button";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './app.min.css';
// https://themesbrand.com/dason/layouts/default/tables-basic.html

class Invite extends Component{
    constructor(props){
        super(props)
        this.state = {
            coins: [],
            userName: '',
            userId: 0,
            userType:0,
            name:'',
            des:'',
            roleList: [],
            inviteList: [],
            roleid:0,
            email:'',
            role:'',
            roleDetails:'',
            showSuccessAlert:false,
            showErrorAlert:false,
            user:'',
        };
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.logOut = this.logOut.bind(this);
        this.roleHendl = this.roleHendl.bind(this);
        this.roleDesHendel = this.roleDesHendel.bind(this);
        this.roleEmailHendel = this.roleEmailHendel.bind(this);
        this.submitRole = this.submitRole.bind(this);
    }

    handleRoleChange(e) {
        let role =  e.target.value;
        this.setState({role: e.target.value});
        fetch(`
    https://crearecoin.com/api/coin/get_role.php?name=${role}`)
            .then(response => response.json())
            .then(data => {
                this.setState({roleDetails: data.result[0]});
                console.log(this.state.roleDetails);
            });
    }
    roleHendl(e){
        this.setState({name: e.target.value});
    }
    roleDesHendel(e){
        this.setState({des: e.target.value});
    }
    roleEmailHendel(e){
        this.setState({email: e.target.value});
    }
    submitRole(){
        console.log(this.state);
        if(this.state.roleid ==0 ){
            fetch(`
            https://crearecoin.com/api/coin/invite.php?
            name=${this.state.name}&des=${this.state.des}&email=${this.state.email}&ownerid=${this.state.userId}`)
                    .then(response => response.json())
                    .then(data => {
                        console.log(data)
                        if(!data.error){
                            this.setState({showSuccessAlert: true});
                            this.setState({role: ''});
                            this.setState({roleDes: ''});
                            this.getAllInvite();
                        }else{
                            this.setState({showErrorAlert: true});
                        }
                    });

        }else{
            fetch(`
            https://crearecoin.com/api/coin/invite.php?
            name=${this.state.name}&des=${this.state.des}&email=${this.state.email}&id=${this.state.roleid}
            &ownerid=${this.state.userId}`)
                    .then(response => response.json())
                    .then(data => {
                        console.log(data)
                        this.setState({role: ''});
                        this.setState({roleDes: ''});
                        this.setState({roleid: 0});
                        this.getAllInvite();
                    });
        }
       
    }

   
    getAllInvite(){
        fetch(`
    https://crearecoin.com/api/coin/invite-list.php?id=${this.state.userId}&usertype=${this.state.userType}`)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if(! data.error){
                    this.setState({inviteList: data.result});
                }
                
            });
    }
    getAllRolle(){
        fetch(`
    https://crearecoin.com/api/coin/role-list.php`)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({roleList: data.result});
            });
    }

    componentDidMount() {
        console.log('hit ')
        let user =JSON.parse(localStorage.getItem('user'));
        if(!user || user=== null){
            this.props.history.push({ 
                pathname: '/login'
            });
        }
        if(user){
            this.setState({userName: user.name});
            this.setState({userId: user.id});
            this.setState({userType: user.user_type});
            this.setState({user: user});
        }
        setTimeout(() => {
            this.getAllInvite();
            this.getAllRolle();
        }, 500);
        
        
    }


    logOut(){
        console.log('logout')
        localStorage.removeItem("user");
        this.props.history.push({
            pathname: '/login'
        });
    }
    handleEdit(e){
        console.log(e)
        this.setState({role: e.name});
        this.setState({roleDes: e.des});
        this.setState({roleid: e.id});
    }
    handleDelete(e){
        fetch(`
    https://crearecoin.com/api/coin/invite.php?delete=`+e.id)
            .then(response => response.json())
            .then(data => {
                this.getAllRolle();
            });
    }


    render(){
        return(
            <>
            <header class="menu-sticky">
                <div class="topnav" id="myTopnav">
                
                <a href="./">VELOCITY MONEY</a>
                
                { this.state.userId != -1 && 
                <a class="log" onClick={this.logOut}>
                        Logout
                </a> }
                { this.state.userId != -1 && 
                <a class="log" href="./dashboard">
                        Dashboard
                </a> }

                { this.state.userId == -1 && 
                <a class="log" href="./login">
                        LOGIN
                </a> }
                <a href="javascript:void(0);" class="icon" onclick="myFunction()">
                    <i class="fa fa-bars"></i>
                </a>
                </div>
            </header>
            <div class="main-contain">
                <section class="iq-tranding-Platform trending-2">
                    <div class="container-floud">
                    <div class="row">
                            <div class="col-sm-6">
                                <div class="heading-title">
                                    
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="heading-title header-test">
                                    <p>{this.state.user.address } &nbsp;&nbsp;
                                    || &nbsp;&nbsp;
                                    {this.state.user.balance } {this.state.user.symbol }
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div className="col-sm-2 overview-block-pb">
                                <ul className="menu">
                                    <li><a href="/dashboard">DASHBOARD</a> </li>
                                    <li><a href="/rollmaster">User role master</a> </li>
                                    <li><a href="/adduser">Subscription Master</a> </li>
                                    <li><a href="/invite">Invite</a> </li>
                                    {this.state.userType !=1  &&
                                    <>
                                    <li><a href="https://shekel.world/coin">Coin</a> </li>
                                    <li><a href="https://shekel.world/marketplace">Marketplace</a> </li>
                                    <li><a href="https://shekel.world/enjoy">Enjoy</a> </li>
                                    
                                    </>}
                                    <li><a href="/report">Reports & Graphs</a> </li>
                                </ul>
                            </div>
                            <div className="col-sm-10 white-background">
                            <div class="row">
         <div class="col-xl-4 col-md-4">
            <div class="ms-panel">
               <div class="ms-panel-header">
                  <h6>Invite link</h6>
               </div>
               <div class="ms-panel-body">
               <div class="form-row">
               <div class="row">
                       <div class='col-sm-12'>
                           {this.state.showSuccessAlert &&
                            <div class="alert alert-success" role="alert" 
                            onClick={(e)=>this.setState({showSuccessAlert: false})}>
                                Subscription added successfully
                            </div>
                            }
                            {this.state.showErrorAlert &&
                            <div class="alert alert-danger" role="alert"
                            onClick={(e)=>this.setState({showErrorAlert: false})}>
                                Something was wrong!!
                            </div>
                             }
                       </div>
                   </div>
                    <div class="col-md-12 mb-3">
                        <label for="validationCustom0001">Name</label>
                        <div class="input-group">
                            <input type="text" class="form-control" 
                            id="validationCustom0001" placeholder=" Name"
                            onChange={this.roleHendl}
                            value={this.state.name}
                             />
                        </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <label for="validationCustom0002">Description</label>
                        <div class="input-group">
                            <input type="text" class="form-control" id="validationCustom0002" 
                            placeholder="Enter Description"
                            onChange={this.roleDesHendel}
                            value={this.state.des} />

                        </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <label for="validationCustom0002">Email</label>
                        <div class="input-group">
                            <input type="text" class="form-control" id="validationCustom0002" 
                            placeholder="Enter Email"
                            onChange={this.roleEmailHendel}
                            value={this.state.email} />

                        </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <label for="validationCustom0002">Role: </label>
                        <div class="input-group">
                        <select class="form-control" 
                        value={this.state.role} 
                         onChange={this.handleRoleChange}
                         >
                            <option value="">Select</option>
                            { this.state.roleList &&
                                this.state.roleList.map((coin, index) => (
                                <option key={index}
                                value={coin.name}
                                >
                                    {coin.name}
                                </option>
                                ))
                                }
                        </select>

                        </div>
                    </div>
                    { this.state.roleDetails !=''&&
                                    <div class="col-sm-12 table-response-aa">
                                   
                                   <table className="table table-bordered table-hover"id="tab_logic">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Percentage</th>
                                                    <th className="text-center">Coin</th>
                                                    <th className="text-center">Holding period (days)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.roleDetails && this.state.roleDetails.rows &&
                                            <>
                                            {this.state.roleDetails.rows.map((row, idx)=>( 
                                                <tr  key={idx}>
                                                    <td>{ row.perc }</td>
                                                    <td>{row.coin } </td>
                                                    <td>{row.days }</td>
                                                    {/* <td>{
                                                        new Date(row.days).getTime() <= new Date().getTime() &&
                                                        
                                                        <button type='button'
                                                        class=' btn-success btn-ruds'
                                                        onClick={() => this.byeCoin(row.coin)}
                                                        > Buy & Transfer
                                                        </button>
                                                        }

                                                        {
                                                        new Date(row.days).getTime() >= new Date().getTime() &&
                                                        <button type='button'
                                                        class=' btn-info btn-ruds'
                                                        > Buy & Transfer
                                                        </button>
                                                        }
                                                    </td> */}
                                                </tr>
                                            )) }
                                            </>
                                            }
                                            </tbody>
                                        </table>
                                    
                                    </div>
                                    }
                    { this.state.roleid ==0 &&
                        <button class="btn btn-primary mt-4 d-inline w-20"
                        onClick={this.submitRole}
                        type="button">Submit</button>
                    }
                    { this.state.roleid > 0 &&
                        <button class="btn btn-warning mt-4 d-inline w-20"
                        onClick={this.submitRole}
                        type="button">Update</button>
                    }
                    
                </div>
               </div>
            </div>
         </div>
         <div class="col-xl-8">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">List</h4>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive hight-fixed">
                                        <table class="table mb-0">

                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Description</th>
                                                    <th>Email</th>
                                                    <th>Date</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            { this.state.roleList &&
                                                this.state.inviteList.map((role, index) => (
                                                <tr key={index}>
                                                    <th scope="row">1</th>
                                                    <td>{role.name} </td>
                                                    <td>{role.des} </td>
                                                    <td>{role.email} </td>
                                                    <td>{ role.create_date} </td>
                                                    <td>Pending</td>
                                                    <td>
                                                    <button type="button" 
                                                    class="btn btn-info btn-rounded waves-effect waves-light"
                                                    onClick={() => this.handleEdit(role)}
                                                    >Edit</button>
                                                    <button type="button" 
                                                    class="btn btn-danger btn-rounded waves-effect waves-light"
                                                    onClick={() => this.handleDelete(role)}
                                                    >Delete</button>
                                                    </td>
                                                </tr>
                                                ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
      </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            </>
        )
    }
}
export default Invite;