// Userlist
import React, { Component } from 'react';
import Button from "react-bootstrap/Button";

import DataTable from 'react-data-table-component';
import FilterResults from 'react-filter-search';

import Dropdown from 'react-bootstrap/Dropdown';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// https://themesbrand.com/dason/layouts/default/tables-basic.html

class Userlist extends Component{
    constructor(props){
        super(props)
        this.state = {
            coins: [],
            userName: '',
            userId: 0,
            userType:0,
            user:'',
            searchId:'',
            searchName:'',
        };
        this.logOut = this.logOut.bind(this);
        this.seachById = this.seachById.bind(this);
        this.seachByName = this.seachByName.bind(this);
        this.searchByEmail = this.searchByEmail.bind(this);
        this.searchByAge = this.searchByAge.bind(this);
        this.searchByRole = this.searchByRole.bind(this);
        this.searchByPhone = this.searchByPhone.bind(this);
        this.searchByGender = this.searchByGender.bind(this);
        this.handleEkyc = this.handleEkyc.bind(this);
        this.handleApprove = this.handleApprove.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(){

    }
    handleApprove(user){
        console.log(user);
        fetch(`
        https://crearecoin.com/api/coin/approve.php?id=${user.id}`)
        .then(response => response.json())
        .then(data => {
            for (let i = 0; i < this.state.coins.length; i++) {
                if(this.state.coins[i].id ==user.id ){
                    this.state.coins[i].approve_status = 1;
                }                
            }
            setTimeout(() => {
                var xx = this.state.coins;
                this.setState({coins : xx});
            }, 100);
            console.log(this.state.coins);
        });
    }
    handleDeny(user){
        console.log(user);
        fetch(`
        https://crearecoin.com/api/coin/notapprove.php?id=${user.id}`)
        .then(response => response.json())
        .then(data => {
            for (let i = 0; i < this.state.coins.length; i++) {
                if(this.state.coins[i].id ==user.id ){
                    this.state.coins[i].approve_status = 3;
                }                
            }
            setTimeout(() => {
                var xx = this.state.coins;
                this.setState({coins : xx});
            }, 100);
            console.log(this.state.coins);
        });
    }
    handleEkyc(user){
        console.log(user)
        fetch(`
            https://crearecoin.com/api/coin/ekyc.php?email=${user.user_name}&fname=${user.fname}&lname=${user.lname}&pnone=${user.pnone}`)
            .then(response => response.json())
            .then(data => {
                console.log(this.state.coins);
                toast.success('eKYC has been sent!!');
                this.getAllCoins();
            });
    }
    seachById(se){
        this.setState({searchId: se})
        console.log(se)
    }
    
    seachByName(se){

        this.setState({searchName: se})
        let value = se;
        if (value !='') {
            var coins = this.state.coins.filter((coin)=>{
                return coin.fname.toLowerCase().search(value) != -1;
            });
            this.setState({coins: coins});
        }else{
            this.getAllCoins();
        }
       
    }
    searchByEmail(se){
        let value = se.target.value;
        if (value !='') {
        var coins = this.state.coins.filter((coin)=>{
                return coin.user_name.toLowerCase().search(value) != -1;
            });
            this.setState({coins: coins});
        }else{
            this.getAllCoins();
        }
    }
    searchByAge(se){
        let value = se.target.value;
        if (value !='') {
       var coins = this.state.coins.filter((coin)=>{
            return coin.age.toLowerCase().search(value) != -1;
        });
        this.setState({coins: coins});
        }else{
            this.getAllCoins();
        }
    }
    searchByRole(se){
        let value = se.target.value;
        if (value !='') {
       var coins = this.state.coins.filter((coin)=>{
            return coin.role.toLowerCase().search(value) != -1;
        });
        this.setState({coins: coins});
        }else{
            this.getAllCoins();
        }
    }
    searchByPhone(se){
        let value = se.target.value;
        if (value !='') {
       var coins = this.state.coins.filter((coin)=>{
            return coin.pnone.toLowerCase().search(value) != -1;
        });
        this.setState({coins: coins});
        }else{
            this.getAllCoins();
        }
    }
    searchByGender(se){
        let value = se.target.value;
        if (value !='') {
       var coins = this.state.coins.filter((coin)=>{
            return coin.gender.toLowerCase().search(value) != -1;
        });
        this.setState({coins: coins});
        }else{
            this.getAllCoins();
        }
    }
    
    getAllCoins(){
        setTimeout(() => {
            fetch(`
    https://crearecoin.com/api/coin/get_all_users.php`)
            .then(response => response.json())
            .then(data => {
                if(!data.error){
                    this.setState({coins: data.result});
                }
                //
                console.log(this.state.coins);
            });
        }, 200);
    }
    componentDidMount() {
        console.log('hit ')
        
        let user =JSON.parse(localStorage.getItem('user'));
        if(!user || user=== null){
            window.location.replace('./');
        }
        this.getAllCoins();
        if(user){
            this.setState({userName: user.name});
            this.setState({userId: user.id});
            this.setState({userType: user.user_type});
            this.setState({user: user});
        }
        
    }
    logOut(){
        console.log('logout')
        localStorage.removeItem("user");
        this.props.history.push({ 
            pathname: '/login'
        });
    }
    handleEdit(e){
        console.log(this.props)
        //this.props.history.push("adduser");
        this.props.history.push({ 
            pathname: '/adduser',
            state: e
        });
    }
    handleDelete(e){
        fetch(`
    https://crearecoin.com/api/coin/sign-up.php?delete=`+e.id)
            .then(response => response.json())
            .then(data => {
                this.getAllCoins();
            });
    }

    render(){
        return(
           <>
           <header class="menu-sticky">
           <ToastContainer />
                <div class="topnav" id="myTopnav">
                
                <a href="./">VELOCITY MONEY</a>
                
                { this.state.userId != -1 && 
                <a class="log" onClick={this.logOut}>
                        Logout
                </a> }
                { this.state.userId != -1 && 
                <a class="log" href="./dashboard">
                        Dashboard
                </a> }

                { this.state.userId == -1 && 
                <a class="log" href="./login">
                        LOGIN
                </a> }
                <a href="javascript:void(0);" class="icon" onclick="myFunction()">
                    <i class="fa fa-bars"></i>
                </a>
                </div>
            </header>
            <div class="main-contain">
                <section class="iq-tranding-Platform trending-2">
                    <div class="container-floud">
                    <div class="row">
                            <div class="col-sm-6">
                                <div class="heading-title">
                                    
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="heading-title header-test">
                                    <p>{this.state.user.address } &nbsp;&nbsp;
                                    || &nbsp;&nbsp;
                                    {this.state.user.balance } {this.state.user.symbol }
                                    </p>
                                </div>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div className="col-sm-2 overview-block-pb">
                                <ul className="menu">
                                    <li><a href="/dashboard">DASHBOARD</a> </li>
                                    <li><a href="/rollmaster">User role master</a> </li>
                                    <li><a href="/adduser">Subscription Master</a> </li>
                                    <li><a href="/invite">Invite</a> </li>

                                    {this.state.userType !=1  &&
                                    <>
                                    <li><a href="https://shekel.world/coin">Coin</a> </li>
                                    <li><a href="https://shekel.world/marketplace">Marketplace</a> </li>
                                    <li><a href="https://shekel.world/enjoy">Enjoy</a> </li>
                                    
                                    </>}
                                    { this.state.userType =='1' &&  
                                    <li><a href="/report">Report</a> </li>}
                                </ul>
                            </div>
                            <div className="col-sm-10 white-background">
                            
                            <div class="table-responsive">
                     <table class="table table-bordered">
                        <thead>
                            <tr>
                                <td>
                                    
                                </td>
                                <td>
                                    <input type='text' value={this.state.searchName} onChange={(e)=>this.seachByName(e.target.value)}/>
                                </td>
                                <td>
                                    <input type='text' onChange={(e)=>this.searchByEmail(e)}/>
                                </td>
                                <td>
                                    <input type='text' onChange={(e)=>this.searchByAge(e)}/>
                                </td>
                                <td>
                                    <input type='text' />
                                </td>
                                <td>
                                    <input type='text' onChange={(e)=>this.searchByRole(e)}/>
                                </td>
                                <td>
                                    <input type='text' onChange={(e)=>this.searchByPhone(e)}/>
                                </td>
                                <td>
                                    <input type='text' onChange={(e)=>this.searchByGender(e)}/>
                                </td>
                                <td>
                                    
                                </td>
                            </tr>
                           <tr>
                              <th scope="col">#</th>
                              <th scope="col">Name</th>
                              <th scope="col">Email</th>
                              <th scope="col">Age</th>
                              <th scope="col">Date</th>
                              <th scope="col">Role</th>
                              <th scope="col">Phone</th>
                              <th scope="col">Gender</th>
                              <th scope="col">Action</th>
                           </tr>
                        </thead>
                        <tbody>
                            { this.state.coins &&
                        this.state.coins.map((coin, index) => (
                            <tr key={index}>
                              <th scope="row">{index+1} </th>
                              <td>{coin.name?coin.name:(coin.fname+' '+coin.lname)} </td>
                              <td>{coin.user_name} </td>
                              <td>{coin.age}</td>
                              <td>{coin.create_date}</td>
                              <td>{coin.role}</td>
                              <td>{coin.pnone}</td>
                              <td>{coin.gender}</td>
                              <td>
                                  { coin.ekyc_send_status==0 && 
                                <button type="button" 
                                    class="btn btn-info btn-rounded waves-effect waves-light"
                                    onClick={() => this.handleEkyc(coin)}
                                    >eKYC</button>
                                  }
                                  { coin.ekyc_send_status==1 && 
                                  <>
                                    <Dropdown>
                                    <Dropdown.Toggle variant="warning" id="dropdown-basic">
                                   
                                        {
                                          coin.approve_status ==1 && 
                                          <>
                                          Approved
                                          </>  
                                        } 
                                        {
                                          coin.approve_status ==3 && 
                                          <>
                                          Not Approved
                                          </>  
                                        } 
                                         {
                                          coin.approve_status=='0'  && 
                                          <>
                                           Pending eKYC
                                          </>  
                                        }  
                                        
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu onSelect={this.handleSelect}>
                                        <Dropdown.Item 
                                        onClick={() => this.handleEkyc(coin)}
                                         >Resend</Dropdown.Item>
                                        <Dropdown.Item  
                                        onClick={() => this.handleApprove(coin)} 
                                        >Approve</Dropdown.Item>
                                        <Dropdown.Item 
                                        onClick={() => this.handleDeny(coin)}
                                         >Not Approve</Dropdown.Item>
                                    </Dropdown.Menu>
                                    </Dropdown>
                                    
                                   
                                    </>
                                  }
                              <button type="button" 
                                class="btn btn-info btn-rounded waves-effect waves-light"
                                onClick={() => this.handleEdit(coin)}
                                >View & Edit</button>
                                <button type="button" 
                                class="btn btn-danger btn-rounded waves-effect waves-light"
                                onClick={() => this.handleDelete(coin)}
                                >Delete</button>
                              </td>
                           </tr>
                        ))
                        }
                           
                        </tbody>
                     </table>
                  </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
           </>
        )
    }
}
export default Userlist;