import React, { Component } from 'react';

class Team extends Component {

  render() {

    return (
        <div>
          <div class="team">  </div>
          <div class="teamSub"> </div>
          <div class="teamSub">  </div>

          <div class="teamPics">

            <div class="circlePic">
              
              <div class="userName">  </div>
              <div class="userText">  </div>
              <div class="userText">   </div>
            </div>

            <div class="circlePic">
             
              <div class="userName">  </div>
              <div class="userText">  </div>
              <div class="userText">   </div>
            </div>

            <div class="circlePic">
             
              <div class="userName">  </div>
              <div class="userText">  </div>
              <div class="userText">   </div>
            </div>
          </div>

        </div>

    );
  }
}

export default Team;
