import React, { useState, useEffect, Component } from "react";

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import KaspTokens from "../contracts/KaspTokens.json";
import KaspTokenSale from "../contracts/KaspTokenSale.json";
import getWeb3 from "../getWeb3";

import '../css/style.css';
import '../css/topnav.css';
import '../css/custom.css';
import '../css/responsive.css';
import '../css/timeline.min.css';
import '../css/mediaelementplayer.min.css';

import Button from '@material-ui/core/Button';
import Start from './Start';
import About from './About';
import Whitepaper from './Whitepaper';
import Roadmap from './Roadmap';
import Contribute from './Contribute';
import Team from './Team';

import Login from './Login';

import scrollToComponent from 'react-scroll-to-component';





class Home extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      balance: 0,
      symbol: '',
      dc:'1223',
      account: '',
      tokenprice: 0,
      KaspTokenSaleInstance: '',
      tokenSold: 0,
      available: 0,
      userId : -1,
      user:'',
    };
    this.logOut = this.logOut.bind(this);
    this.login = this.login.bind(this);
    this.mypanel = this.mypanel.bind(this);
  }


  async loadBlockChain() {
    const web3 = await getWeb3();
    //const accounts = web3.eth.getAccounts().then();
    const accounts = await web3.eth.getAccounts();
    this.setState({address: accounts});
    const networkId = await web3.eth.net.getId();
    const KaspTokensInstance = new web3.eth.Contract(
      KaspTokens.abi,
      KaspTokens.networks[networkId] &&
        KaspTokens.networks[networkId].address
    );
    console.log(KaspTokensInstance);

    const KaspTokenSaleInstance = new web3.eth.Contract(
      KaspTokenSale.abi,
      KaspTokenSale.networks[networkId] &&
        KaspTokenSale.networks[networkId].address
    );
    this.setState({KaspTokenSaleInstance: KaspTokenSaleInstance});

    const balance = await KaspTokensInstance.methods
          .balanceOf(accounts[0])
          .call();
         // console.log(balance);
          this.setState({balance: balance});

        const symbol = await KaspTokensInstance.methods.symbol().call(); // symbol
        this.setState({symbol: symbol});

        const to_name = await KaspTokensInstance.methods.name().call();

        const tokenSold = await KaspTokenSaleInstance.methods
          .tokenSold()
          .call();
          this.setState({tokenSold: tokenSold});
        const available = await KaspTokenSaleInstance.methods
          .tokensLeft()
          .call();
          
        const sum = ~~available + ~~tokenSold;
        this.setState({available: sum});
        const tokenprice = await KaspTokenSaleInstance.methods
          .tokenprice()
          .call();
          console.log(tokenSold);
          this.setState({tokenprice: tokenprice});


  }
  handleOnSubmit = async () => {
    console.log('click')
    /* const res = await KaspTokenSaleInstance.methods.buyTokens(obj.amount).send({
      from: accounts[0],
      value: obj.amount * obj.tokenPrice,
    }); */
    // setObj({ ...obj, token_name: to_name });
  };

  componentDidMount() {
    //this.loadBlockChain()
    let user = localStorage.getItem('user');
    if(user){
        user = JSON.parse(user)
        this.setState({userId: user.id })
        this.setState({user: user});
    }
  }
  


  handleInputChange() {
    /* this.setState({
      isOnline: status.isOnline
    }); */
  }
  login(){
    window.open("/login");
  }
  mypanel(){
    window.open("/mypanel");
  }
  
  logOut(){
      console.log('logout')
      localStorage.removeItem("user");
      this.setState({userId: -1} )
  }


  render() {
    return (
      <div>
         {/* <div id="loading">
        <div id="loading-center">
            <div class='loader loader2'>
                <div>
                    <div>
                        <div>
                            <div>
                                <div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    <header class="menu-sticky">
        <div class="topnav" id="myTopnav">
        
        <a href="">VELOCITY MONEY</a>
        { this.state.userId != -1 && 
        <a class="log" href="./dashboard">
                Dashboard
        </a> }
        { this.state.userId == -1 && 
        <a class="log" href="./login">
                LOGIN
        </a> }
        <a href="javascript:void(0);" class="icon" onclick="myFunction()">
            <i class="fa fa-bars"></i>
        </a>
        </div>
    </header>
    <div class="main-contain">
        <section class="overview-block-pb">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                    </div>
                </div>
                
            </div>
        </section>
        <section class="iq-tranding-Platform overview-block-pb trending-2">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="heading-title">
                            </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        
                        {/* <img class="img-logo" src="./SHEKEL-WORLD-COIN.png" 
                        alt="Italian Trulli" /> */}
                        <img class="img-logo" src="./velocity.png" 
                        style={{height:'300px'}}
                        alt="Italian Trulli" />
                        
                    </div>
                    <div class="col-lg-6 align-self-center">
                        <div className="video-box">
                          <img src={require('../shekal.jpeg')} alt="" />
                          {/* <iframe 
                          src="https://player.vimeo.com/video/644526851?h=fe22992768&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
                          frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
                           allowfullscreen title="Shekel Concept Summary.mp4"
                           className="video-frame"
                           ></iframe> */}
                          </div>
                          
                        
                    </div>
                </div>
            </div>
        </section>
    </div>
      </div>
    );
  }
}

export default Home;
