import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import Mypanel from "./components/Mypanel";
import Rollmaster from "./components/Rollmaster";
import Adduser from "./components/Adduser";
import Userlist from "./components/Userlist";
import Invite from "./components/Invite";
import Profile from "./components/Profile";
import Dashboard from "./components/Dashboard";
import Report from "./components/Report";
import Graphs from "./components/Graphs";


class App extends Component {

  render() {
    return (
      <Router>
        <div id="container">
         
          <Switch>
          <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/mypanel" component={Mypanel} />
            <Route exact path="/rollmaster" component={Rollmaster} />
            <Route exact path="/adduser" component={Adduser} />
            <Route exact path="/userlist" component={Userlist} />
            <Route exact path="/invite" component={Invite} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/report" component={ Report } />
            <Route exact path="/graphs" component={ Graphs } />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;