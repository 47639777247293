import React, {Component} from 'react';
import * as jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';

import Chart from "react-apexcharts";

class Report extends Component {
    constructor(props){
        super(props)
        this.state = {
            coins: [],
            userName: '',
            userId: 0,
            userType:0,
            user:'',
            searchId:'',
            searchName:'',
            roleList: [],
            searchForm:'',
            searchTo:'',
            searchRole:'',
            options: {
                chart: {
                  id: "basic-bar"
                },
                xaxis: {
                  categories: ['unrestricted','restricted',  'Total']
                }
              },
              series: [
                {
                  name: "series-1",
                  data: []
                }
              ]
        };
        this.logOut             = this.logOut.bind(this);
        this.searchSubscription = this.searchSubscription.bind(this);
    }
    handlePdf = () => {
        const input = document.getElementById('page');
    
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'px', 'a4');
                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();
                console.log(height)
    
                pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
                pdf.save("report.pdf");
            });
    };

    searchSubscription(){
        console.log('hit..')
        var whereData = [];
        if(this.state.searchForm !='' && this.state.searchTo !=''){
            whereData.push({form: this.state.searchForm, to: this.state.searchTo });
        }
        if(this.state.searchRole !=''){
            whereData.push({role: this.state.searchRole});
        }

        fetch(`https://crearecoin.com/api/coin/search_subscription.php?form=${this.state.searchForm}&to=${this.state.searchTo}&role=${this.state.searchRole}`)
            .then(response => response.json())
            .then(data => {
                if(!data.error){
                    this.setState({coins: data.result});

                    var rcv   = 0;
                    var unrcv = 0;
                    for (let i = 0; i < data.result.length; i++) {
                        if(data.result[i].revCoin){
                            rcv += data.result[i].revCoin;
                        }                        
                        if(data.result[i].UnrevCoin){
                            unrcv += data.result[i].UnrevCoin;
                        }                        
                    }

                    var series = [{
                        name: "series-1",
                        data: [unrcv,rcv,(rcv+unrcv)]
                      }]
                    this.setState({series: series });
                }else{
                    this.setState({coins: []});
                }
                //
                console.log(data);
            });
        console.log(whereData);
    }
    
    getAllCoins(){
        setTimeout(() => {
            fetch(`
    https://crearecoin.com/api/coin/get_all_users.php`)
            .then(response => response.json())
            .then(data => {
                if(!data.error){
                    this.setState({coins: data.result});
                    var total = 0;
                    var rcv   = 0;
                    var unrcv = 0;
                    for (let i = 0; i < data.result.length; i++) {
                        if(data.result[i].revCoin){
                            rcv += data.result[i].revCoin;
                        }                        
                        if(data.result[i].UnrevCoin){
                            unrcv += data.result[i].UnrevCoin;
                        }                        
                    }

                    var series = [{
                        name: "series-1",
                        data: [unrcv,rcv,(rcv+unrcv)]
                      }]
                    this.setState({series: series });
                }
                //
                console.log(this.state.coins);
            });
        }, 200);
    }
    
    logOut(){
        console.log('logout')
        localStorage.removeItem("user");
        this.props.history.push({ 
            pathname: '/login'
        });
    }
    handleEdit(e){
        console.log(this.props)
        //this.props.history.push("adduser");
        this.props.history.push({ 
            pathname: '/adduser',
            state: e
        });
    }
    handleDelete(e){
        fetch(`
    https://crearecoin.com/api/coin/sign-up.php?delete=`+e.id)
            .then(response => response.json())
            .then(data => {
                this.getAllCoins();
            });
    }
    getAllRolle(){
        fetch(`
        https://crearecoin.com/api/coin/role-list.php`)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({roleList: data.result});
            });
    }
    componentDidMount() {
        console.log('hit ')
        let user =JSON.parse(localStorage.getItem('user'));
        if(!user || user=== null){
            window.location.replace('./');
        }
        this.getAllCoins();
        if(user){
            this.setState({userName: user.name});
            this.setState({userId: user.id});
            this.setState({userType: user.user_type});
            this.setState({user: user});
        }
        this.getAllRolle();
        
    }
  render(){
    const reportHeader = {
        border: "1px solid black",
      };
    return (
        
        <>
        <header class="menu-sticky">
             <div class="topnav" id="myTopnav">
             
             <a href="./">VELOCITY MONEY</a>
             
             { this.state.userId != -1 && 
             <a class="log" onClick={this.logOut}>
                     Logout
             </a> }
             { this.state.userId != -1 && 
             <a class="log" href="./dashboard">
                     Dashboard
             </a> }

             { this.state.userId == -1 && 
             <a class="log" href="./login">
                     LOGIN
             </a> }
             <a href="javascript:void(0);" class="icon" onclick="myFunction()">
                 <i class="fa fa-bars"></i>
             </a>
             </div>
         </header>
         <div class="main-contain">
             <section class="iq-tranding-Platform trending-2">
                 <div class="container-floud">
                 <div class="row">
                         <div class="col-sm-6">
                             <div class="heading-title">
                                 
                             </div>
                         </div>
                         <div class="col-sm-6">
                             <div class="heading-title header-test">
                                 <p>{this.state.user.address } &nbsp;&nbsp;
                                 || &nbsp;&nbsp;
                                 {this.state.user.balance } {this.state.user.symbol }
                                 </p>
                             </div>
                         </div>
                         
                     </div>
                     <div class="row">
                         <div className="col-sm-2 overview-block-pb">
                             <ul className="menu">
                                 <li><a href="/dashboard">DASHBOARD</a> </li>
                                 <li><a href="/rollmaster">User role master</a> </li>
                                 <li><a href="/adduser">Subscription Master</a> </li>
                                 <li><a href="/invite">Invite</a> </li>
                                 {this.state.userType !=1  &&
                                    <>
                                    <li><a href="https://shekel.world/coin">Coin</a> </li>
                                    <li><a href="https://shekel.world/marketplace">Marketplace</a> </li>
                                    <li><a href="https://shekel.world/enjoy">Enjoy</a> </li>
                                    
                                    </>}

                                 <li><a href="/report">Report & Graphs</a>
                                    <ul>
                                        <li><a href="/report">Report</a></li>
                                        <li><a href="/graphs">Graphs</a></li>
                                    </ul>
                                  </li>
                             </ul>
                         </div>
                         <div className="col-sm-10 white-background">
                         <form className='search-form' style={{margin: "20px"}}>
                             <div className='row'>
                                 <div className='col-sm-12'>
                                    
                                 </div>
                             </div>
                             <div className='row'>
                                <div class="form-group col-sm-6">
                                
                                    <label class="sr-only">Form date</label>
                                    <input type="date" class="form-control" 
                                    value={this.state.searchForm}
                                    onChange={(e)=>{this.setState({searchForm: e.target.value }) }}
                                    placeholder="Form date" />
                                </div>
                                <div class="form-group col-sm-6">
                                    <label class="sr-only">To date</label>
                                    <input type="date" class="form-control"
                                    value={this.state.searchTo}
                                    onChange={(e)=>{this.setState({searchTo: e.target.value})}}
                                    placeholder="To date" />
                                </div>
                             </div>
                             <div className='row'>
                             <div class="form-group col-sm-6">
                                    <label class="sr-only">Select Role</label>
                                    <select className='form-control' 
                                    value={this.state.searchRole}
                                    onChange={(e)=>{this.setState({searchRole: e.target.value})}} >
                                        <option value=''>--Select role--</option>
                                        {
                                        this.state.roleList.map((user, index) => {
                                            return <option key={index} value={user.name}>{user.name}</option>;
                                            })
                                        }
                                    </select>
                                </div>
                                 <div className='col-sm-6'>
                                 <button type="button" style={{margin: "5px"}}
                                 class="btn btn-warning pull-right"
                                 onClick={this.handlePdf} 
                                 >Export to PDF</button>

                                 <button type="button" 
                                 style={{margin: "5px"}}
                                 class="btn btn-primary pull-right"
                                 onClick={this.searchSubscription}
                                 >Search</button>
                                 </div>
                             </div>
                             <div className='row'>
                                 <div className='col-sm-12'>
                                     &nbsp;
                                 </div>
                             </div>
                         </form>
                         <div class="table-responsive" id='page' style={{minHeight: '297mm' }}>
                             <div style={reportHeader}>
                                <div>
                                    <h4 style={{textAlign:'center',background: '#c5c5c5' }}>Report</h4>
                                </div>
                                <div className='row color-white' style={{background:'#767676', color:'white' }}>
                                    <div className='col-sm-8'>
                                        <img style={{height:'130px' }} src='./SHEKEL-WORLD-COIN.png'  />
                                        <p style={{marginTop: '20px'}}>Total report: <b>{this.state.coins.length} </b> </p>
                                    </div>
                                    <div className='col-sm-4'>
                                        <p>Form date: <b>{this.state.searchForm} </b> </p>
                                        <p>To date: <b>{this.state.searchTo} </b></p>
                                        <p>Role name: <b>{this.state.searchRole} </b></p>
                                    </div>
                                </div>
                                

                             </div>
                  <table class="table table-bordered">
                     <thead>
                        <tr>
                           <th scope="col">#</th>
                           <th scope="col">Name</th>
                           <th scope="col">Email</th>
                           <th scope="col">Age</th>
                           <th scope="col">Date</th>
                           <th scope="col">Role</th>
                           <th scope="col">Phone</th>
                           <th scope="col">restricted coin</th>
                           <th scope="col">unrestricted  coin</th>
                        </tr>
                     </thead>
                     <tbody>
                         { this.state.coins && this.state.coins.length >0 &&
                     this.state.coins.map((coin, index) => (
                         <tr key={index}>
                           <th scope="row">{index+1} </th>
                           <td>{coin.name?coin.name:(coin.fname+' '+coin.lname)} </td>
                           <td>{coin.user_name} </td>
                           <td>{coin.age}</td>
                           <td>{coin.create_date}</td>
                           <td>{coin.role}</td>
                           <td>{coin.pnone}</td>
                           <td>{coin.revCoin}</td>
                           <td>{coin.UnrevCoin}</td>
                        </tr>
                     ))
                     }
                        { this.state.coins && this.state.coins.length ==0 &&
                        <tr>
                            <td colSpan='9' align='center'><b> Data not found</b></td>
                        </tr>
                        }
                     </tbody>
                  </table>
               </div>
                         </div>
                     </div>
                 </div>
             </section>
         </div>
        </>
    );
  }
}

export default Report;